<template>
  <div>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" x-small v-on="on">
          <v-icon >{{ icon }}</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item v-for="(item, index) in menuItems" :key="index" color="primary" @click="handleClick(index)">
          <v-list-item-icon>
            <v-icon :color="item.color" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <confirm-dialog
      v-if="dialogs.delete" 
      :key="`cd-dim-${documentItem.documentitemid}`"
      :message="`Are you sure you want to delete ${documentItemTypeName} (${documentItem.documentitemid}) and all its translations?`"
      :title="`Delete ${documentItemTypeName}`"
      bar-color="red"
      @close="dialogs.delete = false"
      @delete="deleteDocumentItemAndTranslations(documentItem.documentitemid)"
    />

    <json-dialog 
      v-if="dialogs.raw"
      :key="`jd-dim-${documentItem.documentitemid}`"
      :title="`${documentItemTypeName} Object`"
      :text="documentItem"
      @close="dialogs.raw = false"
    />

  </div>
</template>

<script>
import configs from '@/configs'
import types from '@/helpers/types'
import JsonDialog from '@/components/shared/JsonDialog'
import ConfirmDialog from '@/components/shared/ConfirmDialog'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DocumentItemMenu',
  components: {
    ConfirmDialog,
    JsonDialog
  },
  props: {
    documentItem: {
      type: Object,
      default: null
    },
    icon: {
      type: String,
      default: 'mdi-cog-outline'
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    dialogs: {
      raw: false,
      delete: false,
      translationForm: false
    },
    menuItems: [
      {
        id: 'edit-document-item-type',
        title: undefined,
        icon: 'mdi-pencil-outline',
        color: 'blue',
        click() {
          this.$router.push({ 
            name: 'documentitem_edit', 
            params: {
              documentitemid: this.documentItem.documentitemid
            }
          })
        }
      },
      {
        title: 'Add Translation',
        icon: 'mdi-plus',
        color: 'blue',
        click() {
          this.$router.push({ 
            name: 'documentitem_translations_add', 
            params: {
              documentitemid: this.documentItem.documentitemid
            }
          })
        }
      },
      {
        title: 'Raw Data',
        icon: 'mdi-code-json',
        color: 'blue',
        click() {
          this.dialogs.raw = true
        }
      },
      {
        id: 'delete-document-item-type',
        title: undefined,
        icon: 'mdi-delete',
        color: 'red',
        click() {
          this.dialogs.delete = true
        }
      }
    ]
  }),
  computed: {
    ...mapState('documents', ['document', 'dataStructure']),
    ...mapGetters('documents', ['getDocumentDataStructure', 'selectedLanguagesByDocument', 'getDocumentItemDataStructureById']),
    documentItemTypeName() {
      return types.getDocumentItemTypeNameById(this.documentItem.documentitemtypeid)
    },
    getAllowedChildren() {
      const docStructure = this.dataStructure[this.document.documenttypeid.name]

      if (!docStructure) { return [] }

      const documentItemTypeName = types.getDocumentItemTypeNameById(this.documentItem.documentitemtypeid)
      const docItemStructure = docStructure.documentItems[documentItemTypeName]
      
      if (!docItemStructure) {
        return []
      }

      if (!('allowedChildren' in docItemStructure)) {
        console.error(`DocumentItemMenu.getAllowedChildren, with this (${JSON.stringify(docItemStructure)}) we have no allowedChildren in docItemStructure`)
        
        return []
      }

      return (docItemStructure.allowedChildren ? docItemStructure.allowedChildren : [])
    },
    isLeafNode()  {
      const documentItemTypeName = types.getDocumentItemTypeNameById(this.documentItem.documentitemtypeid)
      const docStructure = this.dataStructure[this.document.documenttypeid.name]
      const docItemStructure = docStructure.documentItems[documentItemTypeName]

      return docItemStructure && docItemStructure.leafNode
    }
  },
  watch: { },
  mounted() {
    this.updateMenuTitles()
    this.addAllowedChildren()
  },
  methods: {
    handleClick(index) {
      this.menuItems[index].click.call(this)
    },
    addAllowedChildren() {
      if (!this.getAllowedChildren) { return }
      this.getAllowedChildren.forEach((allowedType) => {
        this.menuItems.unshift({
          id: 'add-document-item-type',
          title: `Add ${allowedType}`,
          icon: 'mdi-plus',
          color: 'blue',
          click() {
            this.$router.push({ 
              name: 'documentitem_add',
              params: {
                documentitemtypeid: this.documentItem.documentitemtypeid 
              },
              query: { 
                parentdocumentitemid: this.documentItem.documentitemid,
                documentitemtype: allowedType 
              }
            })
          }
        })
      })
    },
    updateMenuTitles() {
      this.menuItems.forEach((item, index) => {
        if (item.id === 'edit-document-item-type') {
          Vue.set(this.menuItems[index], 'title', `Edit ${this.documentItemTypeName}`)
        }
        if (item.id === 'delete-document-item-type') {
          Vue.set(this.menuItems[index], 'title', `Delete ${this.documentItemTypeName}`)
        }
      })
    },
    async deleteDocumentItemAndTranslations(id) {
      await this.$store.dispatch('documentItems/deleteDocumentItemsWithChildren', id)
      this.dialogs.delete = false
      this.$emit('close')
    }
  }
}
</script>