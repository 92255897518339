<template>
  <div>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" x-small v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="(item, index) in menuItems" :key="index" color="primary" @click="handleClick(index)">
          <v-list-item-icon>
            <v-icon :color="item.color" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <confirm-dialog 
      v-if="dialogs.delete"
      :key="`cd-ditm-${documentItemTranslation.id}`"
      :message="`Are you sure you want to delete translation (${documentItemTranslation.id})?`"
      :title="`Delete ${translationLanguage} Translation`"
      bar-color="red"
      @close="dialogs.delete = false"
      @delete="deleteDocumentItemTranslation(documentItemTranslation.id)"
    />

    <json-dialog
      v-if="dialogs.raw"
      :key="`jd-ditm-${documentItemTranslation.id}`"
      :title="`${translationLanguage} Translation Object`"
      :text="documentItemTranslation"
      @close="dialogs.raw = false"
    />

  </div>
</template>

<script>
import configs from '@/configs'
import JsonDialog from '@/components/shared/JsonDialog'
import ConfirmDialog from '@/components/shared/ConfirmDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentItemTranslationActions',
  components: {
    ConfirmDialog,
    JsonDialog
  },
  props: {
    documentItemTranslation: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    dialogs: {
      raw: false,
      delete: false,
      edit: false
    },
    menuItems: [
      {
        title: 'Edit translation',
        icon: 'mdi-circle-edit-outline',
        color: 'blue',
        click() {
          this.$router.push({ 
            name: 'documentitem_translations_edit', 
            params: {
              documentitemid: this.documentItemTranslation.documentitemid,
              documentitemtranslationid: this.documentItemTranslation.id
            }
          })
        }
      },
      {
        title: 'History',
        icon: 'mdi-history',
        color: 'blue',
        click() {
          this.goToHistoryUrl(this.adminv1HistoryUrl)
        }
      },
      {
        title: 'Edit raw version',
        icon: 'mdi-briefcase-edit-outline',
        color: 'blue',
        click() {
          this.goToChangeUrl(this.adminv1ChangeUrl)
        }
      },
      {
        title: 'Raw Data',
        icon: 'mdi-code-json',
        color: 'blue',
        click() {
          this.dialogs.raw = true
        }
      },
      {
        title: 'Delete Translation',
        icon: 'mdi-delete',
        color: 'red',
        click() {
          this.dialogs.delete = true
        }
      }
    ]
  }),
  computed: {
    ...mapGetters('languages', ['languageEnglishNameById']),
    translationLanguage() {
      return this.languageEnglishNameById(this.documentItemTranslation.languageid)
    }
  },
  watch: { },
  mounted() {
  },
  methods: {
    handleClick(index) {
      this.menuItems[index].click.call(this)
    },
    goToChangeUrl() {
      window.open(configs.application.docAdmin.adminv1.documentItemTranslationChange + this.documentItemTranslation.id)
    },
    goToHistoryUrl() {
      window.open(configs.application.docAdmin.adminv1.documentItemTranslationChange + this.documentItemTranslation.id + '/history')
    },
    deleteDocumentItemTranslation(id) {
      this.$store.dispatch('documentItems/deleteDocumentItemTranslation', id).then((r) => {
        this.dialogs.delete = false
      })
      
    }
  }
}
</script>